import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { LocalStorage } from "../site/localStorage";
import {
  checkoutLoading,
  checkoutLoadSuccess,
  checkoutError,
  checkoutSubmitting,
  promoLoading,
  promoError,
} from "actions/ce-checkout-actions";
import { fetchAuthState } from "actions/next-user-actions";
import {
  destroyEProtectHooks,
  submitPaymentMethodWithTokenForStandardCheckout,
} from "actions/payment-method-actions.js";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import {
  CECheckout,
  StandardCheckoutConstants,
  clientSideErrorMessages,
  EVENTS_CUSTOM_CASTLE,
  CEBagItemTypes,
} from "rtr-constants";
import {
  requestedFlagAndExperimentSelector,
  flagsAndExperimentNames,
} from "components/source/hoc/with-flags-and-experiments";
import { navigateTo } from "helpers/location-helpers";
import AuthActions from "actions/auth-actions";
import { StatusCodes } from "http-status-codes";
import Cookies from "universal-cookie";
import { getUserEmail, getUserId, isAnonymous } from "../components/source/hoc/with-user-data";

import HeapHelpers, { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import {
  firePurchaseAnalytics,
  fireRemoveFromCheckoutAnalytics,
  fireAddPromoAnalytics,
  fireRemovePromoAnalytics,
  fireEnhancedConversionAnalytics,
} from "helpers/ce-standard-checkout-analytics-helpers";
import * as castleHelper from "../helpers/castle-helper";
import { errorCodes } from "helpers/error-message-maps/auth";
import Routes from "routes";

const actions = {
  paymentMethodsSuccess: createAction(ActionTypes.CE_CHECKOUT_USER_PAYMENT_METHODS_SUCCESS),
  checkoutIdCurrentlyCreating: createAction(ActionTypes.CE_CHECKOUT_ID_CURRENTLY_CREATING),
  placeOrderDisabled: createAction(ActionTypes.CE_CHECKOUT_PLACE_ORDER_DISABLED),
  limitedAccountCheckoutEmail: createAction(ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_SUCCESS),
  limitedAccountCheckoutEmailError: createAction(ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_ERROR),
  limitedAccountCheckoutEmailLoading: createAction(ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_LOADING),
  limitedAccountCheckoutEmailExists: createAction(ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_EXISTS),
  limitedAccountCheckoutEmailChallenged: createAction(ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_CHALLENGED),

  getAndDispatchCheckout: checkoutId => {
    return dispatch => {
      return $.get(`/api/checkout/${checkoutId}`).then(
        res => {
          dispatch(checkoutLoadSuccess(res));
          return Promise.resolve(res);
        },
        err => {
          return Promise.reject(err);
        }
      );
    };
  },

  updateAndSubmitCheckout: (
    checkoutId,
    shippingValues = null,
    billingValues = null,
    submitNewCC = false,
    conciergeData,
    onFailure
  ) => {
    return async function (dispatch, getState) {
      dispatch(checkoutError(null));
      dispatch(checkoutSubmitting(true));

      /**
       * this calls an API endpoint to add a new address for the user. we only
       * need to do this if the user isn't just using their default address.
       */
      try {
        await $.patch({
          url: `/api/checkout/${checkoutId}`,
          data: JSON.stringify({
            checkoutId,
            shippingValues,
            billingValues, // sending these just for address validation
          }),
          contentType: "application/json",
        });

        if (shippingValues) {
          const state = getState();
          fireEnhancedConversionAnalytics(shippingValues, getUserEmail(state?.userData), getUserId(state?.userData));
        }
      } catch (e) {
        if (e.status === StatusCodes.UNAUTHORIZED) {
          dispatch(
            AuthActions.showAuthModal({
              triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT,
            })
          );
          throw new Error("Unauthorized");
        } else {
          dispatch(checkoutError(CECheckout.errorCodeToCopy(e.responseText)));
          dispatch(checkoutSubmitting(false));
          return;
        }
      }

      /**
       * Room for improvement with this flow; basic functionality for MVP
       * Consider firing on the select/deselect of the concierge checkbox and
       * adding error handling
       */
      if (conciergeData?.optIn) {
        actions.optInToConcierge(conciergeData?.phone);
      }

      /**
       * signal the CC iframe to fetch the eProtect token, and then call
       * the API endoint to add the payment method given the billing form values.
       * like with the shipping form, we only need to do this if the user isn't
       * just using their default payment method.
       */
      if (submitNewCC) {
        dispatch(
          submitPaymentMethodWithTokenForStandardCheckout(
            {
              billingPayloadValues: billingValues,
              checkoutId: checkoutId,
              url: `/api/checkout/${checkoutId}/paymentMethod`,
            },
            async () => {
              castleHelper.logCustomEvent({
                name: EVENTS_CUSTOM_CASTLE.PAYMENT_METHOD_UPDATE,
              });
              HeapHelpers.fireHeapEvent("add_new_payment_method", { checkoutId });
              dispatch(actions.placeOrder(checkoutId, onFailure));
            },
            res => {
              dispatch(checkoutSubmitting(false));

              let error;

              /**
               * eProtect errors come back from the handler as readable text, so just show them.
               * they likely mean the user needs to correct their CC input, or some fluke happened,
               * so don't call onFailure() (which re-renders the form)
               */
              if (res?.eProtectErrorText) {
                error = res.eProtectErrorText;
              } else if (res?.responseText) {
                error = CECheckout.errorCodeToCopy(res.responseText);

                /**
                 * if the error is that the billing address is invalid, don't re-render the form, so
                 * the user can correct their input and try again
                 */
                if (res.responseText !== "invalid-billing-address") {
                  onFailure();
                }
              } else {
                error = res.errors;

                if (error !== clientSideErrorMessages.creditCard.notFilled) {
                  onFailure();
                }
              }

              dispatch(checkoutError(error));

              // destroy the eProtect hooks so they're not rebuilt on the user's next attempt
              destroyEProtectHooks();
            }
          )
        );
      } else {
        // user's not submitting a new CC, so just place the order
        dispatch(actions.placeOrder(checkoutId, onFailure));
      }
    };
  },

  placeOrder: (checkoutId, onFailure) => {
    return async function (dispatch, getState) {
      dispatch(checkoutLoading(true));
      dispatch(checkoutError(null));

      // sending aspire context - to be tested and confirmed
      const cookies = new Cookies();
      const aspireContext = cookies.get("aspireTransactionId") ?? "";
      const browserId = cookies.get("RTR_ID");
      const sessionId = cookies.get("RTR_SESS");
      const context =
        (aspireContext ? "aspireTransactionId:" + aspireContext + "," : "") +
        "browser_id:" +
        browserId +
        ",session_id:" +
        sessionId;

      if (aspireContext) {
        cookies.remove("aspireTransactionId", { path: "/" });
      }

      return $.post(`/api/checkout/${checkoutId}/placeOrder`, { context }).then(
        res => {
          const state = getState(),
            isSubscriptionBagItem = state?.ceCheckout?.bagItems?.some(
              bagItem => bagItem?.type === CEBagItemTypes.SUBSCRIPTION
            ),
            isDACFlagEnabled = requestedFlagAndExperimentSelector(
              flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION
            )(state);

          // if we're in DAC flow, we will fire purchase analytics events on the order complete page
          // due to a race condition between page navigation and pixels firing. But, we need to persist
          // checkout via localStorage in order to fire these events on the order complete page because
          // checkout is destroyed upon successful order placement.
          if (isSubscriptionBagItem && isDACFlagEnabled) {
            const localStorageClient = new LocalStorage(CECheckout.LocalStorageNameSpace);
            localStorageClient.set("ceCheckout", state?.ceCheckout);
            navigateTo(`/ce-checkout/order-complete/${res.orderId}/${checkoutId}`);
          } else {
            firePurchaseAnalytics(res.orderId, state?.ceCheckout, state?.userData?.userProfile, state?.ceBag);
            castleHelper.logCustomEvent({
              name: EVENTS_CUSTOM_CASTLE.PLACE_ORDER,
              properties: {
                checkout_id: checkoutId,
                order_id: res?.orderId,
              },
            });
            actions.goToOrderConfirmationPage(res.orderId, state?.isLocal);
          }
        },
        res => {
          /**
           * same as above. if unauthorized, pop the auth modal and on the auth
           * success callback, just refresh the page. we don't want to place the
           * order at this time
           */
          if (res.status === StatusCodes.UNAUTHORIZED) {
            dispatch(
              AuthActions.showAuthModal({
                triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT,
              })
            );

            throw res;
          }

          if (onFailure) {
            onFailure(res.responseJSON);
          }

          dispatch(checkoutError(CECheckout.errorCodeToCopy(res?.responseJSON?.validationError ?? res.statusText)));
          dispatch(checkoutLoading(false));
          dispatch(checkoutSubmitting(false));
        }
      );
    };
  },

  getOrCreateCheckoutFromGroup: bagGroup => {
    return function (dispatch, getState) {
      dispatch(actions.checkoutIdCurrentlyCreating(bagGroup.id));
      dispatch(checkoutLoading(true));

      const bagItemIds = bagGroup.bagItems.map(bagItem => bagItem.id);

      if (bagItemIds.length === 0) {
        dispatch(actions.checkoutIdCurrentlyCreating(null));
        return;
      }

      return createAjaxAuthRetry({
        url: "/checkout/createByGroup",
        type: "POST",
        data: { bagItemIds },
      })
        .then(({ data }) => {
          /**
           * we now have the checkout ID, so at this point we want to navigate the user to
           * the page by that checkout ID - this means potentially navigating from SFRuby
           * to SFNext.
           *
           * this works fine for qa, stage and prod. but on local, the two apps run on
           * different ports. in that case, we need to construct an absolute URL to navigate to.
           *
           * we'll use Redux's `isLocal` value to determine whether we're on local. this is set
           * server-side by Ruby and NextJS and pulled from dotenv - this is necessary because
           * this also has to work with local apple pay testing, where we have to proxy local
           * traffic through nginx.
           *
           * that means we can't use getEnvironmentName() here because that gleans the environment
           * by checking the **browser's current URL** - if we're testing apple pay locally
           * and we therefore are redirecting traffic from qa.renttherunway.com to
           * localhost, then getEnvironmentName() will return "qa" which wouldn't work.
           */

          const state = getState();
          let checkoutUrl = `/ce-checkout/${data}`,
            portSuffix = "";

          if (state?.isLocal) {
            portSuffix = window.location.protocol === "https:" ? ":444" : ":3000";
            checkoutUrl = `//${window.location.hostname}${portSuffix}${checkoutUrl}`;
          }

          navigateTo(checkoutUrl);
        })
        .catch(e => {
          dispatch(actions.checkoutIdCurrentlyCreating(null));
          dispatch(checkoutError(e));
        });
    };
  },

  deleteBagItem: (id, checkoutId, bagItem) => {
    return (dispatch, getState) => {
      dispatch(checkoutError(null));
      const state = getState();
      // if there's only 1 bag item, and we're deleting one, this must be the last one!
      const isThelastBagItem = state?.ceCheckout.bagItems.length === 1;
      const isSubscriptionBagItem = bagItem?.type === CEBagItemTypes.SUBSCRIPTION;
      const isDACFlagEnabled = requestedFlagAndExperimentSelector(
        flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION
      )(state);

      // if the user is in DAC flow, just boot them to the plans page
      if (isSubscriptionBagItem && isDACFlagEnabled && isThelastBagItem && isAnonymous(state.userData)) {
        navigateTo(Routes.LP.plans);
        fireRemoveFromCheckoutAnalytics(bagItem, state.userData?.userProfile, state.ceCheckout, state.bag);
        return;
      }

      dispatch(checkoutLoading(true));

      return createAjaxAuthRetry({
        url: `/api/bagItem/${id}`,
        type: "DELETE",
      })
        .then(() => {
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.REMOVE_FROM_BAG,
            properties: {
              product_id: bagItem?.styleName,
            },
          });

          if (isThelastBagItem) {
            navigateTo("/");
            fireRemoveFromCheckoutAnalytics(bagItem, state.userData?.userProfile, state.ceCheckout, state.bag);
            return;
          }

          return dispatch(actions.getAndDispatchCheckout(checkoutId))
            .then(res => {
              fireRemoveFromCheckoutAnalytics(bagItem, state.userData?.userProfile, state.ceCheckout, state.bag);
              dispatch(checkoutLoadSuccess(res));
              dispatch(checkoutLoading(false));
            })
            .catch(err => {
              const resJson = err.responseJSON;
              dispatch(checkoutError(CECheckout.errorCodeToCopy(resJson)));
              dispatch(checkoutLoading(false));
            });
        })
        .catch(e => {
          dispatch(checkoutError(CECheckout.errorCodeToCopy(e)));
        });
    };
  },

  /**
   * Function to set the shipping address to the given checkout, and will only
   * be used by Apple Pay on standard checkout. Returns a promise so that the
   * Apple Pay wrapper can have the user wait for the response and decide how to
   * handles a resolve or reject.
   */
  setApplePayShippingAddressToCheckout: (checkoutId, shippingContact) => {
    return createAjaxAuthRetry({
      url: `/api/checkout/${checkoutId}`,
      type: "PATCH",
      contentType: "application/json",
      headers: {
        Accept: "application/json",
      },
      data: JSON.stringify({
        shippingValues: {
          city: shippingContact.locality,
          firstName: shippingContact.givenName,
          lastName: shippingContact.familyName,
          phone: shippingContact.phoneNumber,
          postalCode: shippingContact.postalCode,
          street1: shippingContact.addressLines[0],
          street2: shippingContact.addressLines[1] || "",
          zoneCode: shippingContact.administrativeArea,
          default: true,
          shippingOption: StandardCheckoutConstants.SHIP_WITH_APPLE_PAY,
        },
      }),
    });
  },

  /**
   * There are cases where a user ATBs an item with a certain zip, but then will try to
   * place the order shipping to a different zip. This means the item needs to be rebooked,
   * and we need to account for any rebooking failures.
   *
   * This function fires if the user blurs the shipping address zip code field, as well as
   * if the user selects a different address from the radio options. The checkout will be
   * updated with the new zip code, and if any items fail to rebook, an error will be shown.
   */
  onUserChangingShipping: (checkoutId, postalCode, zoneCode, shippingAddressId) => {
    return dispatch => {
      const body = postalCode && zoneCode ? { postalCode, zoneCode } : { shippingAddressId };

      dispatch(checkoutLoading(true));
      dispatch(actions.updateShipping(checkoutId, body))
        .then(res => {
          // if any items failed to rebook, show an error
          if (res?.failedToRefreshItems?.length > 0) {
            dispatch(checkoutError("cant-rebook"));
          }

          // and if the checkout now can't happen because all items failed to rebook, disable the place order button
          if (res.checkoutStatus && res.checkoutStatus.toLowerCase() === CECheckout.CheckoutStatuses.NO_ITEMS) {
            dispatch(actions.placeOrderDisabled(true));
          }
        })
        .catch(() => {
          dispatch(checkoutError(CECheckout.errors.generic));
          dispatch(actions.placeOrderDisabled(true));
        })
        .finally(() => {
          dispatch(checkoutLoading(false));
        });
    };
  },

  updateShipping: (checkoutId, data) => {
    return dispatch => {
      return $.patch({ url: `/api/checkout/${checkoutId}/shipping`, data }).then(
        res => {
          dispatch(checkoutLoadSuccess(res));
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.SHIPPING_ADDRESS_UPDATE,
          });
          return Promise.resolve(res);
        },
        e => {
          return Promise.reject(e.responseJSON);
        }
      );
    };
  },

  goToOrderConfirmationPage: (orderId, isLocal) => {
    navigateTo(`${isLocal ? "//localhost:8080" : ""}/order/complete/${orderId}`);
  },

  applyPromoCode: (checkoutId, promoCode, isAnonymousCheckout) => {
    return async (dispatch, getState) => {
      dispatch(checkoutLoading(true));
      dispatch(promoError(null));
      dispatch(promoLoading(true));

      const route = isAnonymousCheckout
        ? `/api/checkout/limitedAccount/${checkoutId}/promoCode`
        : `/api/checkout/${checkoutId}/promoCode`;

      return $.post(route, { promoCode }).then(
        res => {
          dispatch(checkoutLoadSuccess(res));
          // fire analytics
          const state = getState();
          fireAddPromoAnalytics(checkoutId, promoCode, state?.userData?.userProfile, state?.ceCheckout, state?.ceBag);

          dispatch(checkoutLoading(false));
          dispatch(promoLoading(false));

          return Promise.resolve();
        },
        e => {
          dispatch(promoError(e.responseJSON?.error));
          dispatch(checkoutLoading(false));
          dispatch(promoLoading(false));
          return Promise.reject();
        }
      );
    };
  },

  removePromoCode: (checkoutId, isAnonymousCheckout) => {
    return async (dispatch, getState) => {
      dispatch(promoError(null));
      dispatch(promoLoading(true));

      const route = isAnonymousCheckout
        ? `/api/checkout/limitedAccount/${checkoutId}/promoCode`
        : `/api/checkout/${checkoutId}/promoCode`;

      return $.delete(route).then(
        res => {
          // fire analytics
          const state = getState();
          fireRemovePromoAnalytics(checkoutId, state?.userData?.userProfile, state?.ceCheckout, state?.ceBag);
          dispatch(checkoutLoadSuccess(res));

          if (res.passivePromoError) {
            dispatch(promoError(res.passivePromoError));
          }

          dispatch(promoLoading(false));
          return Promise.resolve(res);
        },
        e => {
          dispatch(promoLoading(false));
          return Promise.reject(e.responseJSON);
        }
      );
    };
  },

  optInToConcierge: phone => {
    $.post(`/api/checkout/concierge`, { phone }).then(
      res => {
        return Promise.resolve(res);
      },
      e => {
        return Promise.reject(e.responseJSON);
      }
    );
  },

  makeLimitedAccount(email) {
    return dispatch => {
      dispatch(actions.limitedAccountCheckoutEmailError(false));
      dispatch(actions.limitedAccountCheckoutEmailLoading(true));

      return $.post(`/api/checkout/limitedAccount`, { email }).then(
        res => {
          dispatch(actions.limitedAccountCheckoutEmailLoading(false));
          dispatch(actions.limitedAccountCheckoutEmail(res.email));
          dispatch(actions.makeCheckoutWithSubscription(res.user.id)).then(checkoutId => {
            // set the URL to the checkout ID, now that we have a checkout
            history.replaceState({ id: checkoutId }, "", `/ce-checkout/${checkoutId}`);
          });
          dispatch(fetchAuthState());
          return;
        },
        e => {
          dispatch(actions.limitedAccountCheckoutEmailLoading(false));

          if (e.status === 409) {
            if (e.responseJSON?.error === StandardCheckoutConstants.FINISH_LIMITED_ACCOUNT) {
              dispatch(
                actions.limitedAccountCheckoutEmailError(StandardCheckoutConstants.FINISH_LIMITED_ACCOUNT_PROMPT)
              );
              return;
            }

            dispatch(actions.limitedAccountCheckoutEmail(email));
            dispatch(actions.limitedAccountCheckoutEmailExists(true));
            return;
          } else if (e.status === 403 && e.responseJSON?.error === errorCodes.CHALLENGED) {
            dispatch(actions.limitedAccountCheckoutEmail(email));
            dispatch(actions.limitedAccountCheckoutEmailChallenged(true));
            return;
          }

          dispatch(actions.limitedAccountCheckoutEmailLoading(false));
          dispatch(actions.limitedAccountCheckoutEmailError(e.responseJSON.error));
        }
      );
    };
  },

  resendChallenge: email => {
    return $.post(`/api/checkout/limitedAccount`, { email, action: "resendChallenge" }).then(
      res => {
        return Promise.resolve(res);
      },
      e => {
        return Promise.reject(e);
      }
    );
  },

  respondToChallenge: (email, checkoutId, challengeResponse) => {
    return $.post(`/api/checkout/limitedAccount/${checkoutId}/challengeResponse`, { email, challengeResponse }).then(
      res => {
        return Promise.resolve(res);
      },
      e => {
        return Promise.reject(e);
      }
    );
  },

  makeCheckoutWithSubscription: (userIdFromContext = null) => {
    return (dispatch, getState) => {
      const state = getState();
      const checkoutId = state.ceCheckout.id;
      const userId = userIdFromContext || getUserId(state.userData);
      return $.post(`/api/checkout/limitedAccount/${checkoutId}/checkout`, { userId }).then(
        res => {
          dispatch(checkoutLoadSuccess(res));
          dispatch(actions.limitedAccountCheckoutEmailLoading(false));

          return Promise.resolve(res.id);
        },
        e => {
          if (e.responseJSON?.error) {
            dispatch(actions.limitedAccountCheckoutEmailError(e.responseJSON?.error));
            dispatch(actions.limitedAccountCheckoutEmailLoading(false));
            return Promise.reject(e.responseJSON?.error);
          }
        }
      );
    };
  },

  setGiftCard: (checkoutId, code, action = "add", pin = null) => {
    return dispatch => {
      dispatch(checkoutLoading(true));

      const requestOptions = {
        url: `/api/checkout/${checkoutId}/paymentMethod/giftcard`,
        type: action === "add" ? "POST" : "DELETE",
      };

      if (action === "add") {
        requestOptions.data = { code, pin };
      }

      return $.ajax(requestOptions).then(
        res => {
          dispatch(checkoutLoading(false));
          dispatch(checkoutLoadSuccess(res));

          return Promise.resolve(res);
        },
        e => {
          let error;
          // if the error string contains "could not find gift card" then show an error
          if (e.responseJSON?.error?.includes("could not find gift card")) {
            error = "We could not find a gift card with that code. Please check your gift card and try again.";
          } else {
            error = CECheckout.errorCodeToCopy(e.responseJSON?.error);
          }

          dispatch(checkoutError(error));
          dispatch(checkoutLoading(false));

          return Promise.reject(e);
        }
      );
    };
  },

  unloadGiftCard: (code, pin) => {
    return dispatch => {
      dispatch(checkoutError(null));
      dispatch(checkoutLoading(true));

      return $.post(`/api/giftcard`, { code, pin }).then(
        res => {
          dispatch(checkoutLoading(false));
          dispatch(actions.paymentMethodsSuccess(res.userPaymentMethods));
          return Promise.resolve(res);
        },
        e => {
          dispatch(checkoutLoading(false));
          dispatch(checkoutError(e.responseJSON?.error));
          return Promise.reject(e.responseJSON?.error);
        }
      );
    };
  },
};

export default actions;

export const {
  applyPromoCode,
  checkoutIdCurrentlyCreating,
  deleteBagItem,
  getOrCreateCheckoutFromGroup,
  goToOrderConfirmationPage,
  makeLimitedAccount,
  onUserChangingShipping,
  optInToConcierge,
  placeOrder,
  placeOrderDisabled,
  removePromoCode,
  setApplePayShippingAddressToCheckout,
  updateAndSubmitCheckout,
  updateShipping,
  limitedAccountCheckoutEmail,
  limitedAccountCheckoutEmailError,
  limitedAccountCheckoutEmailLoading,
  loginExistingUserThenMakeCheckoutWithSubscription,
  makeCheckoutWithSubscription,
  paymentMethodsSuccess,
  resendChallenge,
  respondToChallenge,
  setGiftCard,
  unloadGiftCard,
  getAndDispatchCheckout,
} = actions;
