import { CheckoutLogging } from "rtr-constants/logging";

/**
 * Temporary helper for creating checkout pixels
 * Used until verified against heap data
 */
export const createCheckoutPixel = (objectType, action, user, checkout, bag, bagItem = null, orderId) => {
  const customerType = user?.isCustomer ? "Return" : "First";

  // get group id using first item in checkout bag
  const itemId = checkout?.bagItems?.[0] ?? null;
  const bagGroupId = bag?.bagGroups?.filter(group => group?.bagItems?.find(item => item?.id === itemId)).id ?? null;

  let skus = checkout?.bagItems?.map(item => item.sku) ?? null;
  if (bagItem) {
    skus = bagItem?.sku;
  }

  let paymentOnLoad = false; // assume no payment method selected yet
  let addressOnLoad = "undefined"; // assume no address selected yet
  if (action === "submit_order") {
    paymentOnLoad = true;
    addressOnLoad = "all";
  }

  return {
    object_type: objectType, // should this be based on the order type?
    action: action,
    order_id: orderId ? parseInt(orderId) : null,
    checkout_id: checkout.id,
    group_ids: bagGroupId,
    context: CheckoutLogging.context,
    source: CheckoutLogging.standard,
    shipping_group: "single", // single group checkout
    customer_type: customerType,
    payment_on_load: paymentOnLoad,
    address_on_load: addressOnLoad,
    skus,
    uid: user?.id,
  };
};
