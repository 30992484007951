import HeapHelpers from "helpers/heap-helpers";
import GTMHelpers from "helpers/gtm";
import Converge from "helpers/converge";
import ActionLogger from "action-logger";

import { CheckoutLogging } from "rtr-constants/logging";

import { getPurchaseDetails, removeFromBagCEEvent } from "helpers/heap-ce-helpers";
import { createCheckoutPixel } from "helpers/checkout-pixel-helper";
import * as castleHelper from "./castle-helper";
import { EVENTS_CUSTOM_CASTLE } from "rtr-constants";

import { getAppliedGiftCard } from "./ce-checkout-helpers";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const rtr_id = () => cookies.get("RTR_ID");
const rtr_sess = () => cookies.get("RTR_SESS");

/**
 * GA4 Google Analytics Marketing
 * Converge (Facebook/Social) Marketing
 * Heap (BI)
 * Pixel (BI)
 */

const sendPixelEvent = (objectType, action, userProfile, checkout, bag, bagItem = null, orderId = null) => {
  const pixel = createCheckoutPixel(objectType, action, userProfile, checkout, bag, bagItem, orderId);
  ActionLogger.logAction(pixel);
};

export const firePageLoadAnalytics = (checkout, bag, userData) => {
  GTMHelpers.beginStandardCheckoutEvent(checkout, userData?.userProfile);
  Converge.setUserData(userData).then(() => {
    Converge.trackViewedPage();
    Converge.trackStartedStandardCheckout(checkout);
  });

  const customerType = userData?.userProfile?.isCustomer ? "Return" : "First";
  const pageLoadPixelData = {
    event: "page_load",
    object_type: "checkout",
    source: CheckoutLogging.standard,
    context: window.location.href,
    referrer: ActionLogger.getReferrer(),
    order_id: null,
    checkout_id: checkout.id,
    group_ids: bag?.bagGroups?.map(group => group.id),
    shipping_group: "single",
    customer_type: customerType,
    payment_on_load: false,
    address_on_load: "undefined",
    skus: checkout?.bagItems?.map(item => item.sku),
    uid: userData?.userProfile?.id,
  };
  ActionLogger.logAction(pageLoadPixelData);
};

export const fireEnhancedConversionAnalytics = (shippingValues, email, userId) => {
  GTMHelpers.pushEnhancedConversionDataToDataLayer(shippingValues, email, userId);
};

export const firePurchaseAnalytics = (orderId, checkout, userProfile, bag) => {
  GTMHelpers.purchaseEventFromCheckout(checkout, orderId, userProfile);
  Converge.trackCheckoutConversion(checkout, orderId);

  const purchase = getPurchaseDetails(checkout);
  HeapHelpers.fireHeapEvent("order", {
    promoCode: checkout?.promoCode ?? null,
    purchaseType: purchase.purchaseType,
    purchaseTypeDetail: purchase.purchaseTypeDetail,
    order_id: orderId,
    apple_pay: false, // Apple Pay payments will handle this event within the wrapper classes
    giftCard: !!getAppliedGiftCard(checkout),
    orderTotal: checkout?.charges?.totals?.sub,
  });
  sendPixelEvent("order_summary_form", "submit_order", userProfile, checkout, bag, null, orderId);
};

// same as remove from bag
export const fireRemoveFromCheckoutAnalytics = (bagItem, userProfile, ceCheckout, bag) => {
  const analyticsItem = {
    item_id: bagItem?.sku,
    item_name: bagItem?.displayName,
    affiliation: "Rent The Runway",
    currency: "USD",
    item_brand: bagItem?.designerName,
    discount: "N/A",
    price: bagItem?.pricing?.price,
    item_variant: bagItem?.styleName,
  };
  GTMHelpers.removeFromCartEvent(analyticsItem, bagItem?.sku, userProfile?.id, userProfile?.email);
  // Heap
  removeFromBagCEEvent(bagItem?.type, bagItem?.sku);
  sendPixelEvent("checkout", "remove_item", userProfile, ceCheckout, bag, bagItem);
};

export const fireAddPromoAnalytics = (checkoutId, promoCode, userProfile, checkout, bag) => {
  GTMHelpers.promotionEventFromCheckout(checkout, promoCode, userProfile);
  HeapHelpers.fireHeapEvent("promoCodeAdded", { checkoutId, promoCode, isKifah: false });
  sendPixelEvent("order_summary_form", "apply_promo_code", userProfile, checkout, bag);
  castleHelper.logCustomEvent({
    name: EVENTS_CUSTOM_CASTLE.APPLY_PROMO_CODE,
    properties: {
      checkout_id: checkoutId,
    },
  });
};

export const fireRemovePromoAnalytics = (checkoutId, userProfile, checkout, bag) => {
  HeapHelpers.fireHeapEvent("promoCodeRemoved", { checkoutId, isKifah: false });
  sendPixelEvent("order_summary_form", "remove_promo_code", userProfile, checkout, bag);
  castleHelper.logCustomEvent({
    name: EVENTS_CUSTOM_CASTLE.REMOVE_PROMO_CODE,
    properties: {
      checkout_id: checkoutId,
    },
  });
};

export const sendMakeCheckoutEvents = (source, styleName, membershipTier, isAnonymous) => {
  const hash = {
    action: ActionLogger.RezoAddToCart,
    object_type: "node",
    source: source,
    style_name: styleName,
    base_price: membershipTier?.basePrice,
    slot_count: membershipTier?.slotCount,
    monthly_shipment_limit: membershipTier?.monthlyShipmentLimit,
    base_slot_upgrade_price: membershipTier?.baseSlotUpgradePrice,
    name: membershipTier?.name,
    membership_tier_revision_id: membershipTier?.membershipTierRevisionId,
    browser_id: rtr_id(),
    session_id: rtr_sess(),
    url: window.location.pathname,
  };

  ActionLogger.inferAction(hash);

  if (isAnonymous) {
    HeapHelpers.fireHeapEvent("make_anonymous_checkout", hash);
  }
};
