import _ from "underscore";

//
// FIXME File should no longer be appended with further logic
//
// TODO All future logic should be moved into `constants.js | ACTION_LOGGER`
//      namespace.
//
// NOTE Ensure that new logic changes `objectType` to `object_type`,
//      which is actually the *expected* key
//
const makeLoggingHash = actionArray => {
  return _.reduce(
    actionArray,
    (memo, type) => {
      memo[type] = type;
      return memo;
    },
    {}
  );
};

const LoggingConstants = {
  CheckoutLogging: {
    standard: "standard_checkout",
    source: "new_checkout",
    objectType: "checkout",
    context: "checkout",
    actions: makeLoggingHash(["page_load", "remove_item", "add_promo"]),
    deliveryTypes: makeLoggingHash(["customer", "store"]),
    shipping: {
      objectType: "shipping_form",
      actions: makeLoggingHash([
        "save_address",
        "display_address_validation_form",
        "select_address",
        "click_to_edit",
        "validate_address",
        "toggle_delivery_type",
        "shipping_address_apply",
      ]),
    },
    addressBook: {
      objectType: "address_book",
      actions: makeLoggingHash(["select_address"]),
    },
    billing: {
      objectType: "billing_form",
      actions: makeLoggingHash(["save_credit_card", "click_to_edit", "payment_method_apply"]),
    },
    wallet: {
      objectType: "wallet",
      actions: makeLoggingHash(["select_payment_method"]),
    },
    marketingBanner: {
      objectType: "checkout",
      actions: makeLoggingHash(["display_promo_banner"]),
      promoTypes: makeLoggingHash(["pro_subscription", "rtr_update_subscription", "unlimited_subscription"]),
    },
    orderSummary: {
      objectType: "order_summary_form",
      actions: makeLoggingHash(["apply_gift_card", "apply_promo_code", "remove_promo_code", "submit_order"]),
    },
    rebooking: {
      objectType: "checkout",
      actions: makeLoggingHash(["rebook_modal_display", "rebook_modal_confirm"]),
    },
  },
};

export default LoggingConstants;

export const { CheckoutLogging } = LoggingConstants;
